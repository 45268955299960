window._ = require('lodash');

require('./libs/helper');

require('moment/locale/ja')
require('moment/locale/th')
require('moment/locale/ko')
require('moment/locale/zh-tw')
window.Moment = require('moment');
window.RestUrlParser = require('./libs/RestUrlParser').default;
window.CountryParser = require('./libs/CountryParser').default;
window.MessengerParser = require('./libs/MessengerParser').default;
window.LangKeyParser = require('./libs/LangKeyParser').default;

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

window.Tether = require('tether');
import $ from 'jquery';
window.$ = window.jQuery = $;
import 'jquery-ui/ui/widgets/datepicker.js';
require('bootstrap');


/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

/*
* Include ay.js for event and user tracking library
*/
require('./libs/ay.js');