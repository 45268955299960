Array.prototype.swap = function (indexA, indexB) {
  var temp = this[indexA]
  this[indexA] = this[indexB]
  this[indexB] = temp
}

export const localeMap = {
  'zh-tw': 'zh_tw',
  'en-us': 'en_us',
  'ja-jp': 'ja_jp',
  'ko-kr': 'ko_kr',
  'th-th': 'th_th',
}

export const roomFacilityIds = {
  roomfeatures: 8,
  roomSpaceLayout: 9,
  roomoptions: 10, // bed types
  roomfacility: 11,
  roomlaundry: 12,
  roomkitchen: 13,
  roombath: 14,
  roomsecurity: 15,
}

export const bnbFacilityIds = {
  bnbfeatures: 1,
  bnbfacility: 2,
  bnbEntertainment: 4,
  bnbvalue: 5,
  bnbsafety: 6,
  bnbParentChild: 16,
  bnbInfants: 17,
  bnbPets: 18,
  bnbCamping: 19,
  bnbTransportation: 20,
}

export const multiSpaceRoomTypeIds = [1, 21, 25, 26]
