import _ from 'lodash';

export default class LangKeyParser {
    constructor() {
        this.langTitles = [
            {lang: 1, legacyKey: 'tw', key: 'zh-tw', momentKey: 'zh-tw', name: 'zh-tw'},
            {lang: 2, legacyKey: 'jp', key: 'ja-jp', momentKey: 'ja', name: 'ja-jp'},
            {lang: 3, legacyKey: 'en', key: 'en-us', momentKey: 'en', name: 'en-us'},
            {lang: 4, legacyKey: 'kr', key: 'ko-kr', momentKey: 'ko', name: 'ko-kr'},
            {lang: 5, legacyKey: 'th', key: 'th-th', momentKey: 'th', name: 'th-th'},
            {lang: 7, legacyKey: 'hk', key: 'zh-hk', momentKey: 'zh-hk', name: 'zh-hk'},
            {lang: 8, legacyKey: 'my', key: 'zh-my', momentKey: 'zh-my', name: 'zh-my'},
        ]
    }

    parseKeyByLang(lang) {
        lang = parseInt(lang);
        let result = _.find(this.langTitles, {lang: lang});
        if (_.isEmpty(result)) {
            return null;
        }

        return result.legacyKey;
    }

    parseLangTypeByKey(key) {
        const result = _.find(this.langTitles, {key: key}) || _.find(this.langTitles, {legacyKey: key});
        if (_.isEmpty(result)) {
            return null;
        }

        return result.lang;
    }

    parseMomentLocale(key) {
        const result = _.find(this.langTitles, {key: key}) || _.find(this.langTitles, {legacyKey: key});
        if (_.isEmpty(result)) {
            return 'zh-tw';
        }

        return result.momentKey;
    }
    parseNameLocale(countryId) {

        let result = _.find(this.langTitles, {lang: countryId});
        if (_.isEmpty(result)) {
            return 'zh-tw';
        }

        return result.name;
    }
    parseNameFromKey(key) {
        const result = _.find(this.langTitles, {key: key}) || _.find(this.langTitles, {legacyKey: key});
        if (_.isEmpty(result)) {
            return 'zh-tw';
        }

        return result.name;
    }
    getObjectNameFromKey(object, key, defaultName) {
        // First find name with locale now
        const langType = this.parseLangTypeByKey(key);
        let langTitle = _.find(object, {lang: langType});

        if (langTitle) {
            return langTitle.name
        }

        // Second find name in en
        langTitle = _.find(object, {lang: 3});
        if (langTitle) {
            return langTitle.name
        }

        // cannot find fit name, use defaulName
        return defaultName
    }
}