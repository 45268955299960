;(function () {
  'use strict'
  var e,
    t = function (e, t) {
      return function () {
        return e.apply(t, arguments)
      }
    }
  ;(e = {
    logTracking: !1,
    googleTagManagerKey: window?.asiayo?.config?.googleTagManagerKey,
    enablePushNotification: !1,
    oneSingalKey: '',
    oneSingalSubdomain: '',
    oneSingalSafariKey: '',
  }),
    (window.dataLayer = []),
    (window.AY = (function () {
      function n(n) {
        ;(this.logToggle = t(this.logToggle, this)),
          (this.detectVWO = t(this.detectVWO, this)),
          (this.trackInitateCheckout = t(this.trackInitateCheckout, this)),
          (this.trackViewContent = t(this.trackViewContent, this)),
          (this.trackSearch = t(this.trackSearch, this)),
          (this.trackOrderCompleted = t(this.trackOrderCompleted, this)),
          (this.identify = t(this.identify, this)),
          (this.trackPage = t(this.trackPage, this)),
          (this.trackEvent = t(this.trackEvent, this)),
          (this.loadGTM = t(this.loadGTM, this)),
          (this.apply = t(this.apply, this))
        var o, i
        _.isNull(n) && (this.config = {}),
          (this.config = _.defaults(n, e)),
          (this.defaultProperties = { Hostname: window.location.hostname }),
          null != window.ayData &&
            null != window.ayData.utm_source &&
            ((this.defaultProperties.utm_source =
              window.ayData.utm_source || ''),
            (this.defaultProperties.utm_campaign =
              window.ayData.utm_campaign || ''),
            (this.defaultProperties.utm_medium =
              window.ayData.utm_medium || ''),
            (this.defaultProperties.utm_term = window.ayData.utm_term || ''),
            (this.defaultProperties.utm_content =
              window.ayData.utm_content || '')),
          null != (i = this.getQueryParams()).utm_source &&
            ((this.defaultProperties.utm_source = i.utm_source || ''),
            (this.defaultProperties.utm_campaign = i.utm_campaign || ''),
            (this.defaultProperties.utm_medium = i.utm_medium || ''),
            (this.defaultProperties.utm_term = i.utm_term || ''),
            (this.defaultProperties.utm_content = i.utm_content || '')),
          this.loadGTM(),
          this.config.enablePushNotification &&
            'undefined' != typeof OneSignal &&
            ((window.oneSignal = OneSignal || []),
            (o = {
              appId: this.config.oneSingalKey,
              safari_web_id: this.config.oneSingalSafariKey,
              welcomeNotification: {
                title: '謝謝你，通知訂閱成功',
                message: '繼續在 AsiaYo 尋找喜歡的旅宿吧！',
              },
              autoRegister: !1,
              notifyButton: { enable: !1 },
            }),
            '' !== this.config.oneSingalSubdomain &&
              (o.subdomainName = this.config.oneSingalSubdomain),
            window.oneSignal.push(['init', o])),
          _.delay(this.detectVWO, 2500)
      }
      return (
        (n.prototype.apply = function (e) {
          return (
            null == e && (e = {}),
            _.chain(e || {})
              .clone()
              .defaults(this.defaultProperties)
              .value()
          )
        }),
        (n.prototype.loadGTM = function () {
          var e, t
          !(function (e, t, n, o, i) {
            var a
            ;(e[o] = e[o] || []),
              e[o].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }),
              ((a = t.createElement('script')).async = !0),
              (a.src = 'https://www.googletagmanager.com/gtm.js?id=' + i),
              document.head.appendChild(a)
          })(window, document, 0, 'dataLayer', this.config.googleTagManagerKey),
            document.getElementById('google-tag-manager-script') ||
              (((t = document.createElement('noscript')).id =
                'google-tag-manager-script'),
              ((e = document.createElement('iframe')).src =
                'https://www.googletagmanager.com/ns.html?id=' +
                this.config.googleTagManagerKey),
              (e.height = '0'),
              (e.width = '0'),
              (e.style.cssText = 'display:none;visibility:hidden'),
              t.appendChild(e),
              document.body.appendChild(t))
        }),
        (n.prototype.getQueryParams = function (e) {
          var t
          return (
            !!(t = (e || window.location.search).substring(1)) &&
            _.chain(t.split('&'))
              .map(function (e) {
                var t
                return (t = e.split('=')), [t[0], decodeURIComponent(t[1])]
              })
              .fromPairs()
              .value()
          )
        }),
        (n.prototype.trackEvent = function (e, t) {
          return (
            null == t && (t = {}),
            (t = this.apply(t)),
            this.logToggle('track', e, t),
            window.dataLayer.push({
              event: 'logEvent',
              eventType: e,
              eventProperties: t,
            }),
            this.trackRemarkingTag(e, t),
            _.delay(this.clearProperties, 500)
          )
        }),
        (n.prototype.trackPage = function (e, t) {
          return (
            null == t && (t = {}),
            (t = this.apply(t)),
            this.logToggle('page', e, t),
            window.dataLayer.push({
              event: 'logEvent',
              eventType: 'Showed ' + e + ' Page',
              eventProperties: t,
            }),
            _.delay(this.clearProperties, 500)
          )
        }),
        (n.prototype.identify = function (e, t) {
          return (
            null == t && (t = {}),
            (t = this.apply(t)),
            (e = e.toLowerCase()),
            this.logToggle('identify', e, t),
            window.dataLayer.push({
              event: 'identify',
              userId: e,
              userProperties: t,
            })
          )
        }),
        (n.prototype.trackOrderCompleted = function (e, t) {
          var n, o
          return (
            null == t && (t = {}),
            (t = this.apply(t)),
            window.dataLayer.push({
              event: 'logEvent',
              eventType: 'Completed Order',
              eventProperties: t,
            }),
            this.trackRemarkingTag(e, t),
            (n = {
              order_id: 0,
              revenue: 0,
              total: 0,
              tax: 0,
              currency: '',
              host_id: '',
              city_id: '',
              room_id: '',
              room_name: '',
              payment_method: '',
            }),
            (o = _.defaults(t, n)),
            this.logToggle('purchase', e, t),
            this.logToggle('fb_pixel', 'Purchase', t),
            window.dataLayer.push({
              event: 'purchase',
              purchaseOrderId: o.order_id,
              purchaseTotal: o.total,
              purchaseRevenue: o.revenue,
              purchaseTax: o.tax,
              purchaseRoomName: o.room_name,
              purchaseHostId: o.host_id,
              purchaseCurrency: o.currency,
              purchaseCityId: o.city_id,
              purchasePaymentMethod: o.payment_method,
              purchaseRoomId: o.room_id,
            })
          )
        }),
        (n.prototype.trackSearch = function (e) {
          var t, n
          return (
            null == e && (e = {}),
            (t = {
              content_type: 'hotel',
              content_ids: [],
              checkin_date: '',
              checkout_date: '',
              city: '',
              region: '',
              country: '',
            }),
            (n = _.defaults(e, t)),
            this.logToggle('fb_pixel', 'search keyword: ' + e.keyword, e),
            window.dataLayer.push({
              event: 'facebookPixelEvent',
              fbPixelEventType: 'Search',
              fbProperties: n,
            })
          )
        }),
        (n.prototype.trackViewContent = function (e) {
          var t, n
          return (
            null == e && (e = {}),
            (n = {
              content_type: 'hotel',
              content_ids: '',
              checkin_date: '',
              checkout_date: '',
              city: '',
              region: '',
              country: '',
            }),
            (t = _.defaults(e, n)),
            this.logToggle('fb_pixel', 'View Content', e),
            window.dataLayer.push({
              event: 'facebookPixelEvent',
              fbPixelEventType: 'ViewContent',
              fbProperties: t,
            })
          )
        }),
        (n.prototype.trackInitateCheckout = function (e) {
          var t, n
          return (
            null == e && (e = {}),
            (n = {
              content_type: 'hotel',
              content_ids: '',
              checkin_date: '',
              checkout_date: '',
              city: '',
              region: '',
              country: '',
            }),
            (t = _.defaults(e, n)),
            this.logToggle('fb_pixel', 'Initate Checkout', e),
            window.dataLayer.push({
              event: 'facebookPixelEvent',
              fbPixelEventType: 'InitiateCheckout',
              fbProperties: t,
            })
          )
        }),
        (n.prototype.trackRemarkingTag = function (e, t) {
          var n, o, i
          switch ((null == t && (t = {}), e)) {
            case 'Showed Index Page':
              o = 'home'
              break
            case 'Showed Listing Page':
              o = 'searchresults'
              break
            case 'Showed Host Page':
              o = 'offerdetail'
              break
            case 'Showed Order Info Page':
              o = 'conversionintent'
              break
            case 'Completed Order':
              o = 'conversion'
              break
            default:
              o = 'other'
          }
          if (((i = void 0 === t.revenue ? '' : t.revenue), '' !== o))
            return (
              (n = t.bnb_id),
              window.dataLayer.push({
                event: 'fireRemarketingTag',
                google_tag_params: {
                  hrental_id: n,
                  hrental_pagetype: o,
                  hrental_totalvalue: i,
                },
              }),
              _.delay(this.clearProperties, 500)
            )
        }),
        (n.prototype.clearProperties = function () {
          return window.dataLayer.push({ eventProperties: void 0 })
        }),
        (n.prototype.detectVWO = function () {
          var e
          return (e = window._vwo_exp_ids)
            ? _.each(
                e,
                (function (e) {
                  return function (t) {
                    var n
                    if (null != (n = e.variation(t)))
                      return e.trackEvent('Experiment Viewed', n)
                  }
                })(this)
              )
            : null
        }),
        (n.prototype.variation = function (e) {
          var t, n, o
          return (n = window._vwo_exp) &&
            n[e] &&
            (t = n[e]).ready &&
            (o = t.combination_chosen)
            ? {
                experimentId: e,
                experimentName: t.name,
                variationId: o,
                variationName: t.comb_n[o],
              }
            : null
        }),
        (n.prototype.isBrowserSupportPush = function () {
          return (
            !!this.config.enablePushNotification &&
            !!window.oneSignal.isPushNotificationsSupported()
          )
        }),
        (n.prototype.isSubscribedPush = function (e) {
          return (
            !!this.config.enablePushNotification &&
            !!_.isFunction(e) &&
            window.oneSignal.push([
              'getNotificationPermission',
              function (t) {
                switch (t) {
                  case 'default':
                    return e(!1)
                  case 'granted':
                    return e(!0)
                }
              },
            ])
          )
        }),
        (n.prototype.subscribePush = function (e) {
          return (
            window.oneSignal.push(['registerForPushNotifications']),
            window.oneSignal.on('subscriptionChange', function (t) {
              return e(t)
            })
          )
        }),
        (n.prototype.addTagForPushService = function (e) {
          return null == e && (e = {}), window.oneSignal.sendTags(e)
        }),
        (n.prototype.logToggle = function (e, t, n) {
          if ((null == n && (n = {}), this.config.logTracking))
            switch (e) {
              case 'track':
                return console.log(
                  '%c Event: %s ',
                  'background: #f04e2f; color: #fff',
                  t,
                  n
                )
              case 'page':
                return console.log(
                  '%c Page: %s ',
                  'background: #4A90E2; color: #fff',
                  t,
                  n
                )
              case 'identify':
                return console.log(
                  '%c Identify: %s ',
                  'background: #f0922f; color: #fff',
                  t,
                  n
                )
              case 'purchase':
                return console.log(
                  '%c Purchase: %s ',
                  'background: #00a7b0; color: #fff',
                  t,
                  n
                )
              case 'fb_pixel':
                return console.log(
                  '%c FB Pixel Event: %s ',
                  'background: #3b5998; color: #fff',
                  t,
                  n
                )
            }
        }),
        n
      )
    })())
}.call(this))
