import _ from 'lodash';

export default class MessengerParser {
    constructor(extraMessengers) {
        if (extraMessengers) {
            this.extraMessengers = extraMessengers;
        }

        this.messengers = [
            {id: 11, key: 'line', name: 'Line'},
            {id: 12, key: 'wechat', name: 'WeChat'},
            {id: 13, key: 'kakao', name: 'KaKao'},
            {id: 14, key: 'whatsapp', name: 'WhatsApp'},
            {id: 15, key: 'chatwork', name: 'ChatWork'},
        ];
    }

    parseIdByKey(key) {
        let result = _.find(this.messengers, {key: key});
        if (_.isEmpty(result)) {
            return null;
        }

        return result.id;
    }

    parseKeyById(id) {
        id = parseInt(id);
        let result = _.find(this.messengers, {id: id});
        if (_.isEmpty(result)) {
            return null;
        }

        return result.key;
    }

    findFromExtra(key) {
        if (_.isEmpty(this.extraMessengers)) {
            return null;
        }
        let result = _.find(this.extraMessengers, {info_type: this.parseIdByKey(key)});

        return result;
    }
}