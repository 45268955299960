import _ from 'lodash';

export default class CountryParser {
    constructor() {
        this.counties = [
            {id: 1, key: 'tw'},
            {id: 2, key: 'jp'},
            {id: 5, key: 'hk'},
            {id: 6, key: 'kr'},
            {id: 13, key: 'th'},
        ]
    }

    parseIdByKey(key) {
        let result = _.find(this.counties, {key: key});
        if (_.isEmpty(result)) {
            return null;
        }

        return result.id;
    }

    parseKeyById(id) {
        id = parseInt(id);
        let result = _.find(this.counties, {id: id});
        if (_.isEmpty(result)) {
            return null;
        }

        return result.key;
    }
}